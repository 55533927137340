import { request } from "./request";
import { deleteParams } from "./util";
import { setLocalToken } from "./token";
import { showFailToast } from "vant";

// 根据环境获取appid
const aliAppId = process.env.NODE_ENV === 'production' ? '2021004157625044':'2021004157621159';

var state = {
  appId: "wxee0908b04ea053c0",
  // 支付宝appid
  aliAppId: aliAppId,
}

// 用户环境
const ua = window.navigator.userAgent.toLowerCase();


function getUrlParam(name) {
  let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
  var r = window.location.search.slice(1).match(reg);
  if (r != null) return decodeURIComponent(r[2]);
  return null;
}

const getTokens = (code, getUserInfo) => {
  return request({
    url: '/weChat/auth',
    params: {
      'code': code,
      'appId': state.appId,
      'getUserInfo': getUserInfo
    }
  })
};

/**
 * 获取支付宝用户token
  * @param code
 * @param getUserInfo
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
const getAliTokens = (code, getUserInfo) => {
  return request({
    url: '/aliPay/auth',
    params: {
      'code': code,
      'appId': state.aliAppId,
      'getUserInfo': getUserInfo
    }
  })
};

// 授权登录
function authBase() {
  let code = getUrlParam("code") // 截取路径中的code，如果没有就去微信授权，如果已经获取到了就直接传code给后台获取token
  // 支付宝宝环境时使用支付宝静默授权
  if (ua.indexOf('alipay') > -1) {
    aliAuthBase();
    return;
  }

  if (!code) {
    window.location.href =
      "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
      state.appId +
      "&redirect_uri=" +
      encodeURIComponent(location.href) +
      "&response_type=code&scope=snsapi_base&state=base#wechat_redirect";
  } else {
    getTokens(code, false).then(res => {
      if(res.success) {
        setLocalToken(res.result);
        window.location.replace(deleteParams(deleteParams(location.href, 'state'), 'code'))
      } else {
        showFailToast("服务异常")
      }
    })
  }
}

// 授权获取微信头像昵称
function authUserInfo(redirectUrl = '') {
  let code = getUrlParam("code") // 截取路径中的code，如果没有就去微信授权，如果已经获取到了就直接传code给后台获取token

  // 支付宝宝环境时使用支付宝用户授权信息
  if (ua.indexOf('alipay') > -1) {
    aliAuthUserInfo(redirectUrl);
    return;
  }
  if (!code) {
    const href = redirectUrl || location.href;
    window.location.href =
      "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
      state.appId +
      "&redirect_uri=" +
      encodeURIComponent(href) +
      "&response_type=code&scope=snsapi_userinfo&state=userinfo#wechat_redirect";
  } else {
    // 获取用户信息
    const queryState = getUrlParam("state");
    // 获取用户信息
    const isGetUserInfo = queryState === 'userinfo';

    getTokens(code, isGetUserInfo).then(res => {
      if (res.success) {
        setLocalToken(res.result);
        window.location.replace(deleteParams(deleteParams(location.href, 'state'), 'code'))
      } else {
        showFailToast("服务异常")
      }
    })
  }
}

/**
 * 支付宝静默授权
 */
const aliAuthBase = () => {
  console.log('ali auth base ---------------------------')
  ap.getAuthCode ({
    appId: state.aliAppId ,
    scopes: ['auth_base'],
    showErrorTip: false,
  },function(result){
    console.log('res', result);
    if (result?.authCode) {
      console.log('code', result.authCode);
      getAliTokens(result.authCode, false).then(res => {
        if (res.success && res.result) {
          setLocalToken(res.result);
          window.location.replace(location.href)
        } else {
          showFailToast("服务异常")
        }
      });
    }
  });
}

/**
 * 获取支付宝用户主动授权
 */
const aliAuthUserInfo = (redirectUrl) => {
  const href = redirectUrl || location.href;

  console.log('ali auth user ---------------------------')
  ap.getAuthCode({
    appId: state.aliAppId ,
    scopes: ['auth_user'],
    showErrorTip: false,
  },function(result){
    if (result?.authCode) {
      console.log('code', result.authCode);
      getAliTokens(result.authCode, true).then(res => {
        if (res.success && res.result) {
          setLocalToken(res.result);
          window.location.replace(href);
        } else {
          showFailToast("服务异常")
        }
      })
    } else {
      showFailToast("服务异常")
    }
  });
}

export { authBase, authUserInfo, getUrlParam }
