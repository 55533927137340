<template>
  <div class="container">
    <div class="good-box" ref="goodRef">
      <div :style="`height: ${showImgWidth}px; width: ${showImgWidth}px;`">
        <canvas id="minimap"></canvas>
      </div>
    </div>

    <van-row justify="space-between" class="op-btn">
      <van-col span="12">
        <template v-if="canUndo">
          <van-icon name="revoke" size="30" color="#fff" @click="undoOperate" />
        </template>
        <template v-else>
          <van-icon name="revoke" size="30" color="darkgray" />
        </template>
        <template v-if="canRedo">
          <van-icon name="revoke" size="30" color="#fff" style="transform: scaleX(-1);margin-left: 1rem"
                    @click="redoOperate" />
        </template>
        <template v-else>
          <van-icon name="revoke" size="30" color="darkgray" style="transform: scaleX(-1);margin-left: 1rem" />
        </template>
      </van-col>
      <van-col span="6">
        <van-button class="save-btn" round size="small" block @click="saveFile">
          保存
        </van-button>
      </van-col>
    </van-row>

    <!-- 姓名输入框，最多15个字， -->
    <van-row align="center" class="name-box">
      <van-field
        v-model="nameText"
        shape="round"
        size="large"
        label-width="70"
        class="name-input-box"
        center
        maxlength="15"
        show-word-limit
        label=""
        placeholder="点击输入文字"
        @update:model-value	="nameTextChange"
      />
    </van-row>

    <div v-show="btnShow" class="content" ref="canvasRef">
      <canvas id="canvas" />
    </div>

    <van-grid v-if="btnShow" :gutter="10" :column-num="1" class="btn-container">
      <van-grid-item class="btn-box">
        <van-uploader :after-read="uploadImg" accept="image/*">
          <van-image width="100%" :src="zidinyiPng" />
          <div class="btn-txt">图片</div>
        </van-uploader>
      </van-grid-item>
    </van-grid>
  </div>
</template>

<script>
import {
  Button,
  Col,
  Grid,
  GridItem,
  Search,
  Icon,
  Image as VanImage,
  Row,
  Space,
  Field,
  showFailToast,
  showLoadingToast,
  Uploader
} from 'vant';
import { nextTick, onMounted, ref } from 'vue';
import { request } from '@/utils/request';
import { getUrlParam } from '@/utils/auth';
import { amountFormat } from '@/utils/util';
import { useRoute } from "vue-router";
import { fabric } from 'fabric';
import Hammer from 'hammerjs';
import '@/utils/fabric/history';
import { deleteControl, rotationControl } from '@/utils/fabric/customRender';
import { createObject, limitObjectArea, limitObjectIntersect } from '@/utils/fabric/utils';

import coinsBgPng from '@/assets/coins_arc_text.png';
import zidinyiPng from '@/assets/zidinyi.png';
import router from "@/router";

export default {
  components: {
    [Space.name]: Space,
    [Search.name]: Search,
    [Field.name]: Field,
    [Button.name]: Button,
    [Col.name]: Col,
    [VanImage.name]: VanImage,
    [Row.name]: Row,
    [Icon.name]: Icon,
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
    [Uploader.name]: Uploader,
  },
  setup() {
    // 接受请求参数
    const route = useRoute();
    const brands = ref([]);
    const finished = ref(false);
    const contactNumber = ref('');
    const nameText = ref('');
    const { params: { productId } } = route;
    // 接受请求参数
    const deviceNo = getUrlParam('deviceNo');
    const typeLogo = getUrlParam('typeLogo');
    // 展示页面上图片尺寸
    const showImgWidth = 270;
    // 页面设计图纸尺寸
    const pageDrawWidth = 220;
    // 设计图纸尺寸
    const drawWidth = 400;
    // 限制最后添加10个元素
    const maxCoinsNum = 10;
    // 画布
    const canvasRef = ref();
    const goodRef = ref();
    let fabricCanvas = null;
    let minimap = null;
    let nameTextPath = null;
    let minimapBgImg = null;
    // 是否可以撤销
    const canUndo = ref(false);
    // 是否可以重做
    const canRedo = ref(false);
    // 自定义图片url
    const dropImgUrl = ref('');
    const canvasHeight = ref(0);

    // 底部按钮显示
    const btnShow = ref(true);
    // 当前选中的元素
    const activeImage = ref(null);
    // 当前选择元素的初始缩放比例
    const initialScale = ref(0);

    onMounted(async () => {
      // 屏幕宽度除以图纸比例尺寸得出页面展示绘图区高度
      await nextTick();
      // 初始化画板
      initCanvas();
      drawBg();
      // 初始化预览图
      initMinimap();

      // 监听事件
      addCanvasEvent();
      // 自定义删除图标
      deleteControl(fabricCanvas);
      // 自定义旋转图标
      rotationControl();

      // nameText.value = 'what your name';
      // nameText.value = '你好';
      // nameTextChange();
    });

    const initCanvas = () => {
      // 初始化画布
      fabricCanvas = new fabric.Canvas('canvas');
      fabricCanvas.controlsAboveOverlay = true;

      // 隐藏所有对象的控制点，只保留顶部旋转点
      ['tl', 'br', 'ml', 'mb', 'mr', 'mt', 'mtr'].forEach(e => {
        fabric.Object.prototype.controls[e] = new fabric.Control({
          visible: false
        });
      });
    };

    // 初始化预览图
    const initMinimap = () => {
      minimap = new fabric.Canvas('minimap', {
        containerClass: 'minimap',
        selection: false,
        fill: 'transparent',
        width: showImgWidth,
        height: showImgWidth,
        clipPath: new fabric.Circle({
          radius: showImgWidth / 2,
          originX: 'center',
          originY: 'center',
          left: showImgWidth / 2,
          top: showImgWidth / 2,
          fill: 'transparent',
          stroke: 'rgba(0,0,0,0.2)',
          strokeWidth: 1
        })
      });
      // 渲染背景图片
      fabric.Image.fromURL(coinsBgPng, function (img) {
        // 图片缩放
        const scale = fabric.util.findScaleToFit(img, minimap).toFixed(4);
        img.scale(scale);
        minimapBgImg = img;
        minimap.setBackgroundImage(minimapBgImg);

        minimap.renderAll();
      });
    }

    // 绘制背景图片
    const drawBg = () => {
      setTimeout(() => {
        canvasHeight.value = canvasRef.value.offsetHeight < pageDrawWidth ? pageDrawWidth : canvasRef.value.offsetHeight;
        canvasHeight.value = canvasRef.value.offsetWidth < canvasHeight.value ? canvasRef.value.offsetWidth : canvasHeight.value;

        // 设置元素的边界，添加的元素不能超过该边界
        fabricCanvas.clipPath = new fabric.Circle({
          radius: canvasHeight.value / 2,
          originX: 'center',
          originY: 'center',
          left: canvasHeight.value / 2,
          top: canvasHeight.value / 2,
          selectable: false,
          fill: 'transparent',
        });

        // 设置canvas的宽高
        fabricCanvas.setWidth(canvasHeight.value);
        fabricCanvas.setHeight(canvasHeight.value);
        // 保存绘制背景图片的操作历史
        fabricCanvas._historySaveAction();
      }, 200);
    }

    // canvas添加事件（对象left和top的坐标是左上角）
    const addCanvasEvent = () => {
      canvasObjectMoving();
      canvasObjectModified();
      canvasObjectTachat();
    }

    // canvas 缩放
    const canvasObjectTachat = () => {
      // 初始化Hammer实例
      const hammer = new Hammer(canvasRef.value);
      hammer.get('pinch').set({ enable: true });
      hammer.on("pinchstart", () => {
        let target = fabricCanvas.getActiveObjects()[0] || '';
        if (!target) {
          // 获取最后一个元素作为放大元素
          target = fabricCanvas.getObjects()[fabricCanvas.getObjects().length - 1] || '';
        }

        if (!target) return;
        // 将最后一个元素选中
        fabricCanvas.setActiveObject(target);
        activeImage.value = target;
        initialScale.value = target.get('scaleX');
      });
      hammer.on("pinchmove", (ev) => {
        if (activeImage.value) {
            const newScale = initialScale.value * ev.scale;
            activeImage.value.set({
                scaleX: newScale,
                scaleY: newScale,
            });
            fabricCanvas.renderAll();
        }
      });
      hammer.on("pinchend", () => {
        activeImage.value = null;
        initialScale.value = 0;
        updateMiniMap();
      });
    };

    // 监控 fabricCanvas 的 moving 方法
    const canvasObjectMoving = () => {
      fabricCanvas.on('object:moving', function (options) {
        fabricCanvas.onHistory();

        limitObjectArea(options)
        limitObjectIntersect(options, fabricCanvas);
      })
    };

    const canvasObjectModified = () => {
      fabricCanvas.on('object:modified', function() {
        updateMiniMap();
      });
      fabricCanvas.on('object:removed', function () {
        updateMiniMap();
      });
    }

    // 撤销
    const undoOperate = () => {
      fabricCanvas.undo();
      canUndo.value = fabricCanvas.canUndo();
      canRedo.value = fabricCanvas.canRedo();
      setTimeout(() => {
        updateMiniMap();
      }, 100);
    };

    // 重做
    const redoOperate = () => {
      fabricCanvas.redo();
      canUndo.value = fabricCanvas.canUndo();
      canRedo.value = fabricCanvas.canRedo();
      setTimeout(() => {
        updateMiniMap();
      }, 100);
    };

    // 更新预览图区域
    const updateMiniMap = () => {
      minimap.clear();
      minimap.setBackgroundImage(minimapBgImg);
      if (nameTextPath) {
        minimap.add(nameTextPath);
      }
      // 提高导出分辨率，使用更高的缩放比
      const scale = 2.0;
      const canvasElement = fabricCanvas.toCanvasElement(scale);

      // 计算合适的尺寸以适应minimap
      const maxSize = 160;
      const imgWidth = canvasElement.width;
      const imgHeight = canvasElement.height;
      const ratio = Math.min(maxSize / imgWidth, maxSize / imgHeight);

      const backgroundImage = new fabric.Image(canvasElement, {
        left: 55,
        top: 55,
        scaleX: ratio,
        scaleY: ratio,
        selectable: false,
        quality: 1.0,
        objectCaching: false
      });
      minimap.add(backgroundImage);
      minimap.renderAll();
    }

    /**
     * 绘制图片
     * @param pointerVpt 图片位置
     * @param isGray 是否为自定义图片
     */
    const drawImg = (pointerVpt, isGray) => {
      fabric.Image.fromURL(dropImgUrl.value, function(obj) {
        // 设置图片大小
        obj.scaleToHeight(120)
        obj.set({
          top: fabricCanvas.height / 2,
          left: fabricCanvas.width / 2,
          textAlign: 'center',
          originX: 'center',
          originY: 'center',
          borderColor: '#6a7bff',
          cornerColor: '#6a7bff',
          cornerSize: 16,
          cornerStyle: 'circle',
          transparentCorners: false,
          lockScalingFlip: false,
          objectCaching: true,
          borderOpacityWhenMoving: 1,
        });
        // 是否为自定义图片
        if (isGray) {
          obj.set({is_gray: true});
        }

        createObject(obj, fabricCanvas);
        updateMiniMap();
      }, {crossOrigin: 'anonymous'});
      canUndo.value = true;
    }

    // 姓名输入库变动时，创建一个圆形路径的文字
    const nameTextChange = () => {
      const length = nameText.value.length;
      const fontSize = length > 8 ? 21 : 28;

      if (nameTextPath) {
        nameTextPath.set({text: nameText.value, fontSize: fontSize});
        if (!nameText.value) {
          minimap.remove(nameTextPath);
          nameTextPath = null;
        }
        minimap.renderAll();
      } else {
        const radius = showImgWidth / 2 - 30;
        const centerX = 0;  // 画布中心X坐标
        const centerY = 0;  // 控制半圆垂直位置

        // 创建半圆路径（从9点到3点，上半圆）
        const semicirclePath = new fabric.Path(`M ${centerX - radius} ${centerY} A ${radius} ${radius} 0 0 1 ${centerX + radius} ${centerY}`, {
          fill: 'transparent',
          selectable: false,
        });
        // 创建文字路径对象
        nameTextPath = new fabric.Text(nameText.value, {
          path: semicirclePath,
          fontSize: fontSize,
          selectable: false,
          fill: '#000',
          left: 30,
          top: 30,
          charSpacing: length > 8 ? 0: 150,
          textAlign: 'center',
          pathAlign: 'center',
        });
        minimap.add(nameTextPath);
        minimap.renderAll();
      }
    };

    // 保存文件
    const saveFile = () => {
      if (minimap.getObjects().length === 0) {
        return;
      }
      // 是否包含自定义图片
      const isGray = fabricCanvas.getObjects().some((obj) => 'is_gray' in obj);

      const toast = showLoadingToast({
        message: '正在保存',
        duration: 0,
        forbidClick: true,
      });

      // 预览图合成的图片
      const combineBgImg = minimap.toDataURL({
        format: 'png',
        quality: 1.0,
        multiplier: 1.8
      });

      // 合成设计图, 使用预览区的元素, 将背景置空
      const ratio = (drawWidth / showImgWidth).toFixed(2);
      minimap.setBackgroundImage(null);
      minimap.backgroundColor = 'white';

      const canvasElement = minimap.toCanvasElement(ratio);
      const combineImg = canvasElement.toDataURL();

      request({
        url: `/h5/createPcDraw`,
        method: 'post',
        data: { deviceNo, productId, combineImg, combineBgImg, isGray, typeLogo },
        headers: { 'Content-Type': 'multipart/form-data' },
      }).then((res) => {
        if (res.success === true && res.result) {
          router.push({ name: 'coinsBuy', params: { productId }, query: { deviceNo, drawId: res.result } })
        }
      }).catch(() => {
        showFailToast('请求出错请稍后再试');
      }).finally(() => {
        toast.close();
      });
    };

    // 自定义图片
    const uploadImg = (file) => {
      if (fabricCanvas.getObjects().length >= maxCoinsNum) {
        showFailToast(`最后只能添加${maxCoinsNum}个元素!`);
        return;
      }
      dropImgUrl.value = file.content;

      // 鼠标坐标转换成画布的坐标（未经过缩放和平移的坐标）
      const point = { x: 100, y: 100 };
      const pointerVpt = fabricCanvas.restorePointerVpt(point);
      // 绘制图片
      drawImg(pointerVpt, true);
    };

    return {
      brands,
      finished,
      deviceNo,
      contactNumber,
      showImgWidth,
      amountFormat,
      productId,
      btnShow,
      canvasRef,
      goodRef,
      canRedo,
      canUndo,
      zidinyiPng,
      nameText,
      redoOperate,
      undoOperate,

      nameTextChange,
      uploadImg,
      saveFile,
    };
  },
}
</script>

<style lang="less" scoped>
.container {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  background-color: #fff;
  .op-btn {
    padding: 0.5rem 1rem;
    background-color: #ffc360;
    .save-btn {
      font-size: 1rem;
      color: #000;
      font-weight: 600;
      background: linear-gradient(to bottom, #e8f8f6, #faeafa);
    }
  }
  .name-box {
    padding: 0.5rem;
    .name-input-box {
      padding: 0px 20px;
      border: 2px solid #ffc360;
      border-radius: 50px;
      :deep(.van-cell__value) {
        display: flex;
        align-items: center;
      }
      :deep(.van-field__body) {
        width: 100%;
      }
      :deep(.van-field__control) {
        height: 2.2rem;
        font-size: 1.1rem;
      }
      :deep(.van-field__word-limit) {
        font-size: 1.1rem;
        color: var(--van-text-color-3);
      }
    }
  }
  .good-box {
    width: 100%;
    height: 270px;
    display: flex;
    align-items: center;
    justify-content: center;
    .van-image {
      position: relative;
    }
  }
  .content {
    background-color: #000;
    width: 100%;
    overflow: hidden;
    flex: 1;
    display: flex;
    justify-content: center;
  }
  .btn-container {
    background-color: #F5EFE7;
    .btn-box {
      .btn-txt {
        width: 100%;
        overflow: hidden;
        color: #fff;
        font-weight: 600;
        background: #ffc360;
        padding-left: var(--van-padding-xs);
        padding-right: var(--van-padding-xs);
        border-radius: 2rem;
        line-height: 1.5rem;
        font-size: 0.9rem;
        text-align: center;
        white-space: nowrap;
      }
      :deep(.van-grid-item__content) {
        background: none;
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}
</style>
