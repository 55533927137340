<template>
  <div v-if="!loading"  class="container">
    <div class="content">
      <div class="good-content">
        <div class="good-img">
          <swiper
            ref="swiper"
            class="swiper"
            :space-between="30"
            :centered-slides="true"
            style="width: 100%; height: 100%; --swiper-navigation-color: #c8c9cc; --swiper-pagination-color:#c8c9cc"
            :modules="modules"
            :navigation="true"
            :pagination="{ clickable: true }"
          >
            <swiper-slide class="slide" v-for="brand in brands" :key="brand.id">
              <img :src="brand" width="100%" height="92%"  alt="" />
            </swiper-slide>
          </swiper>
        </div>
        <div class="good-icon-box">
          <div class="good-icon-img"></div>
        </div>

        <div class="good-info">
          <div class="good-text-row">
            <div class="van-multi-ellipsis--l2 good-name" v-html="product.productName"></div>
          </div>
          <div class="good-text-row">
            <div class="van-ellipsis price-box">
              <div class="text-middle">
                <span class="text-muted m-r-5">&yen; </span>
                <span class="good-price">{{ amountFormat(product.price) }} </span> / {{ product.quantity }}
                <span class="text-muted" style="margin-bottom: 1px">{{ product.unit }}</span>
              </div>
              <div class="text-middle">
                <span class="m-r-5 text-muted" style="margin-bottom: 2px">存量:</span>
                <span>{{ product.amount }}</span>
              </div>
            </div>
          </div>

          <div class="buy-btn-group">
            <div class="btn-box">
              <div :class="disableReceiveBtn ? 'disable-receive-btn' : 'receive-btn'" @click="receive"></div>
              <!-- 可以免费领取时,展示免费领取按钮, 不能领取时展示倒计时 -->
              <template v-if="diffTimer > 0">
                <van-count-down :time="diffTimer" millisecond @finish="timerFinish">
                  <template #default="timeData">
                    <div class="timer">
                      <template v-if="timeData.hours > 0">{{ timeData.hours }}小时</template>
                      {{ timeData.minutes }}分{{ timeData.seconds }}秒 后再次领取
                    </div>
                  </template>
                </van-count-down>
              </template>
            </div>
            <div class="btn-box">
              <div class="buy-btn" @click="sendPay"></div>
            </div>
          </div>
        </div>
      </div>

      <div class="footer">
        <span class="van-ellipsis device-no text-gray">设备编号：{{ deviceNo }}</span>
        <span class="van-ellipsis phone text-gray" v-if="props.contactNumber">
          联系电话：<a class="text-gray" :href="`tel:${props.contactNumber}`">{{ props.contactNumber }}</a>
        </span>
      </div>
    </div>

    <!-- 免费领取弹窗 -->
    <van-overlay v-model:show="showReceiveVisible" width="80vw">
      <div class="wrapper">
        <div class="receive-bg-box">
          <div class="qr-code-box">
            <img :src="product?.imageBackUrl" width="100%" height="100%"  alt="" />
          </div>
        </div>
        <van-icon :name="closeIcon" size="40" color="#fff" @click="showReceiveVisible = false"/>
      </div>
    </van-overlay>

    <!-- 免费领取loading框 -->
    <van-overlay v-model:show="receiveLoading" width="100vw" class="receive-loading"></van-overlay>
  </div>
  <div v-else class="loading-box"></div>
</template>

<script>
import {
  Button,
  Loading,
  Image as VanImage,
  Field,
  Stepper,
  Skeleton,
  Cell,
  Col,
  Row,
  Overlay,
  Icon,
  CountDown,
  showSuccessToast,
  showFailToast,
  showLoadingToast,
} from 'vant';
import { nextTick, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import { amountFormat } from '@/utils/util';
import { request } from '@/utils/request';
import { getUrlParam } from '@/utils/auth';
import closeIcon from '@/assets/goods2/close.png';
import goodsTextImg from '@/assets/goods2/goods-text.png';
import router from "@/router";
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import { Pagination, Navigation } from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

export default {
  props: {
    typeLogo: {
      type: [String, Number],
      default: '',
    },
    contactNumber: {
      type: [String, Number],
      default: '',
    },
    bizSource: {
      type: [String, Number],
      default: '',
    },
  },
  components: {
    [Col.name]: Col,
    [Row.name]: Row,
    [Button.name]: Button,
    [Cell.name]: Cell,
    [Field.name]: Field,
    [Loading.name]: Loading,
    [VanImage.name]: VanImage,
    [Stepper.name]: Stepper,
    [Skeleton.name]: Skeleton,
    [Overlay.name]: Overlay,
    [Icon.name]: Icon,
    [CountDown.name]: CountDown,
    [Button.name]: Button,
    Swiper,
    SwiperSlide,
  },
  setup(props) {
    // 接受请求参数
    const route = useRoute();
    const { params: { productId } } = route;
    const loading = ref(true);
    const disableReceiveBtn = ref(false);
    const num = ref(1);
    // 接受请求参数
    const deviceNo = getUrlParam('deviceNo');
    const loadLastOrder = ref(false);
    const showReceiveVisible = ref(false);
    const receiveLoading = ref(false);
    const receiveMsg = ref('');
    const diffTimer = ref(0);
    const brands = ref([]);
    const modules = [Pagination, Navigation];

    const product = ref({
      productId: productId,
      productName: '',
      price: 10,
      quantity: 0,
      imageUrl: '',
      unit: '',
      name: '',
    });

    onMounted(async () => {
      // 加载产品数据
      await onLoad();
      // 获取用户最后一条订单
      await getLatestOrder();
      // 检查是否需要出货
      await checkNeedReceive();
    });

    const onLoad = async () => {
      loading.value = true;
      await request({
        url: `/h5/device/getProByDevNoAndProId`,
        method: 'get',
        params: { deviceNo, productId },
      }).then((res) => {
        if (res.success === true) {
          product.value = { ...product.value, ...res.result };
          const urls = product.value?.imageUrls || product.value?.imageUrl || '';
          if (urls) {
            const imgs = urls.split(',');
            brands.value.push(...imgs);
          }

          // 加载状态结束
          nextTick(() => {
            loading.value = false;
          });
        }
      }).catch(({ response }) => {
        const { data: { message }} = response;
        router.push({ name: 'error', query: { error: message }})
      });
    };

    // 发起支付
    const sendPay = () => {
      const query = {
        name: product.value.name,
        deviceNo,
        count: num.value,
        timeStamp: Math.floor(Date.now() / 1000),
      };
      router.push({ name: 'pay', query })
    }

    // 检查是否需要出货
    const checkNeedReceive = async () => {
      if (!localStorage.getItem('canFreeGet')) {
        return;
      }
      showLoadingToast({message: '免费领取，系统处理中...', forbidClick: true, duration: 0});
      await request({
        url: '/h5/freeGet',
        method: 'post',
        params: { count: 1, deviceNo, name: product.value.name }
      }).then((res) => {
        localStorage.removeItem('canFreeGet');
        if (res.success) {
          disableReceiveBtn.value = true;
          showSuccessToast({ message: '领取成功，等待机器出货' });
        } else {
          showFailToast(res.error || '查询失败，请联系管理员');
        }
      }).catch(({ response }) => {
        console.log(response)
        const { data: { message }} = response;
        router.push({ name: 'error', query: { error: message }})
      });
    }

    // 加载用户最近一次订单信息
    const getLatestOrder = async () => {
      await request({
        url: '/h5/getLatestOrder',
        method: 'get',
        params: { productId, deviceNo }
      }).then((res) => {
        console.log(res);
        loadLastOrder.value = true;
        if (res.success === true) {
          const lastOrderCreatedAt = res.result?.createdAt || '';
          // 获取当前时间的时间戳
          const currentTimestamp = Date.now();
          // 计算一个小时之前的时间戳（单位：毫秒）
          const oneHourAgoTimestamp = currentTimestamp - (60 * 60 * 1000);
          // 判断给定的时间戳是否在一个小时前
          if (lastOrderCreatedAt && lastOrderCreatedAt > oneHourAgoTimestamp) {
            diffTimer.value = lastOrderCreatedAt - oneHourAgoTimestamp;
            receiveMsg.value = '您已领取过其他商品，请稍后再来';
            return;
          }
          diffTimer.value = 0;
          receiveMsg.value = '';
        } else {
          receiveMsg.value = '您已领取过该商品，请勿重复领取';
        }
      }).catch(({response}) => {
        const {data: {message}} = response;
        // showFailToast('请求出错，请稍后再试！');
        router.push({name: 'error', query: {error: message}})
      });
    };

    // 免费领取
    const receive = () => {
      if (!loadLastOrder.value) {
        showFailToast('数据加载中，请稍后再试!');
        return false;
      }
      if (receiveMsg.value !== '') {
        showFailToast(receiveMsg.value);
        return false;
      }
      if (disableReceiveBtn.value) {
        showFailToast('只能领取一个商品!');
        return false;
      }
      if (!product.value?.imageBackUrl) {
        showFailToast('暂无免费领取途径, 请联系客服人员!');
        return false;
      }
      receiveLoading.value = true;
      setTimeout(() => {
        receiveLoading.value = false;
        nextTick(() => showReceiveVisible.value = true);
      }, 3000);

      // setTimeout(() => {
        // showConfirmDialog({message: '跳转到扫码页'}).then(() => {
        //   router.push({name: 'scanReceiveProduct', params: {productId: productId}})
        // }).catch(() => {
        // });
      // }, 1500);
    }

    // 倒计时结束后处理
    const timerFinish = () => {
      window.location.reload();
    }

    return {
      props,
      num,
      product,
      loading,
      disableReceiveBtn,
      closeIcon,
      goodsTextImg,
      deviceNo,
      diffTimer,
      showReceiveVisible,
      receiveLoading,
      brands,
      modules,

      timerFinish,
      receive,
      sendPay,
      amountFormat,
    }
  }
}
</script>

<style scoped lang="less">
.container {
  height: 100%;
  padding-top: 4.2rem;
  background-color: #eef2f5;
  .content {
    display: flex;
    flex-direction: column;
    height: 100%;

    .good-content {
      flex: 1;
      overflow-y: auto;
      .good-img {
        height: 64vw;
      }
      .good-icon-box {
        height: 3vh;
        width: 100%;
        padding: 0 10px;
        display: flex;
        justify-content: flex-end;

        .good-icon-img {
          width: 30%;
          height: 100%;
          background-image: url("../../../assets/goods2/goods-text.png");
          background-size: 85%;
          background-repeat: no-repeat;
        }
      }

      .good-info {
        padding: 2rem 2rem 0;
        .good-text-row {
          border-bottom: 2px solid #ACAEB0;
          padding: 2.5vw 0;
        }
        .good-name {
          font-size: 1.2rem;
        }
        .price-box {
          font-size: 1.4rem;
          display: flex;
          padding: 0 0.8rem;
          justify-content: space-between;
          .text-middle {
            display: flex;
            align-items: center;
          }
          .text-muted {
            font-size: 1rem;
            color: #747678;
          }
          .m-r-5 {
            margin-right: 5px;
          }
        }
        .buy-btn-group {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 7vw 0.9rem;
          .btn-box {
            width: 165px;
            height: 53px;
            .buy-btn {
              height: 100%;
              background-image: url("../../../assets/goods2/buy-btn.png");
              background-size: 100%;
              background-repeat: no-repeat;
            }
            .disable-receive-btn {
              height: 100%;
              background-image: url("../../../assets/goods2/disable-receive-btn.png");
              background-size: 100%;
              background-repeat: no-repeat;
            }
            .receive-btn {
              height: 100%;
              background-image: url("../../../assets/goods2/receive-btn.png");
              background-size: 100%;
              background-repeat: no-repeat;
            }

            & + .btn-box {
              margin-top: 1.7rem;
            }
            .timer {
              text-align: center;
              font-size: 0.8rem;
              color: red;
            }
          }
        }
      }
    }

    .footer {
      display: flex;
      justify-content: space-between;
      width: 100%;
      overflow: hidden;
      font-size: 0.8rem;
      padding-left: 1rem;
      padding-right: 1rem;
      padding-top: 0.5rem;
      padding-bottom: 1.5rem;

      .device-no {
        margin-right: 0.7rem;
        font-weight: 600;
      }
      .phone {
        font-weight: 600;
      }
      .text-gray {
        color: var(--van-gray-8);
      }
    }
  }
}
.loading-box {
  height: 100%;
  background-image: url("../../../assets/goods2/loading1.png");
  background-size: 100%;
  background-repeat: no-repeat;
}

.receive-loading {
  background-image: url("../../../assets/goods2/loading.png");
  background-size: 100%;
  background-repeat: no-repeat;
}

.wrapper {
  display: flex;
  background: rgba(255, 255, 255, 0.2);
  align-items: center;
  flex-direction: column;
  height: 100%;

  .receive-bg-box {
    width: 40vh;
    height: 57vh;
    margin-top: 15vh;
    position: relative;
    background-image: url("../../../assets/goods2/receive-bg.png");
    background-size: 100%;
    background-repeat: no-repeat;

    .qr-code-box {
      position: absolute;
      left: 7.5vh;
      top: 12.7vh;
      width: 26vh;
      height: 26vh;
    }
  }
}

:deep(.swiper-horizontal>.swiper-pagination-bullets), :deep(.swiper-pagination-bullets.swiper-pagination-horizontal) {
    bottom: 0 !important;
}
</style>
