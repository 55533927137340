<template>
  <div ref="container" class="container">
    <div class="center">
      <div class="good-content">
        <template v-if="loading">
            <van-skeleton title avatar :row="3" />
            <van-skeleton title avatar :row="3" />
            <van-skeleton title avatar :row="3" />
        </template>
        <template v-else>
          <van-row>
            <van-col v-for="product in productList" :key="product.productId" span="12">
              <div v-show="props.typeLogo != 15 || product.name == 1" class="good-box" >
                <router-link :to="{ name: 'goodsDetail', params: { productId: product.productId }, query: { deviceNo } }">
                  <div class="good-img">
                    <van-image radius="8" width="100%" height="100%" fit="cover" :src="product.imageUrl" />
                  </div>
                  <div class="van-multi-ellipsis--l2 good-name" v-html="product.productName"></div>
                </router-link>
              </div>
            </van-col>
            <template v-if="productList.length === 0">
              <van-empty description="没有产品" />
            </template>
          </van-row>
        </template>
      </div>

      <div class="footer">
        <span class="van-ellipsis device-no text-gray">设备编号：{{ deviceNo }}</span>
        <span class="van-ellipsis phone text-gray" v-if="props.contactNumber">
          联系电话：<a class="text-gray" :href="`tel:${props.contactNumber}`">{{ props.contactNumber }}</a>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { getUrlParam } from "@/utils/auth";
import { nextTick, onMounted, ref } from "vue";
import { Col, Empty, Skeleton, Image as VanImage, Row } from 'vant';
import { request } from "@/utils/request";
import router from "@/router";

export default {
  props: {
    typeLogo: {
      type: [String, Number],
      default: '',
    },
    contactNumber: {
      type: [String, Number],
      default: '',
    },
  },
  components: {
    [Col.name]: Col,
    [Empty.name]: Empty,
    [Row.name]: Row,
    [Skeleton.name]: Skeleton,
    [VanImage.name]: VanImage,
  },
  setup(props) {
    // 接受请求参数
    const deviceNo = getUrlParam('deviceNo');
    const container = ref(null);
    const productList = ref([]);
    const loading = ref(true);

    onMounted(async () => {
      // 加载商品列表
      await onLoad();
    });

    const onLoad = () => {
      loading.value = true;
      request({
        url: '/h5/device/getAllProduct',
        method: 'get',
        params: { deviceNo },
      }).then((res) => {
        if (res.success === true) {
          productList.value = res.result;

          // 加载状态结束
          nextTick(() => {
            loading.value = false;
          });
        }
      }).catch(({ response }) => {
        const { data: { message }} = response;
        // showFailToast('请求出错，请稍后再试！');
        router.push({ name: 'error', query: { error: message }})
      });
    };


    return {
      props,
      deviceNo,
      container,
      productList,
      loading,
    };
  },
}
</script>

<style scoped lang="less">
.container {
  height: 100%;
  background-image: url("../../../assets/goods2/bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position-y: -10vh;
  @media screen and (min-width: 400px) {
    .center {
      padding-top: 10.5rem !important;
    }
  }
  .center {
    padding-top: 10rem;
    padding-left: 1rem;
    padding-right: 1rem;
    display: flex;
    flex-direction: column;
    height: 100%;
    .good-content {
      flex: 1;
      overflow-y: auto;
      align-content: flex-start;
    }
    /* 隐藏滚动条 */
    .good-content::-webkit-scrollbar {
      display: none;
    }

    .good-box {
      padding-left: 0.6rem;
      padding-right: 0.6rem;
      margin-bottom: 10vw;
    }

    .good-img {
      overflow: hidden;
      width: 100%;
      height: 30vw;
    }
    .good-name {
      width: 100%;
      text-align: center;
      margin-top: 0.5rem;
      color: #000;
      font-size: 0.9rem;
    }

    .footer {
      display: flex;
      justify-content: space-between;
      width: 100%;
      overflow: hidden;
      font-size: 0.8rem;
      padding-top: 0.5rem;
      padding-bottom: 1.5rem;

      .device-no {
        margin-right: 0.7rem;
        font-weight: 600;
      }
      .phone {
        font-weight: 600;
      }
      .text-gray {
        color: var(--van-gray-8);
      }
    }
  }
}
</style>
