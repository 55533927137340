<template>
  <div class="container">
    <div class="content">
      <div class="head-title">
        <span class="van-ellipsis device-no">历史创作素材</span>
      </div>

      <!-- 产品列表 -->
      <van-list
          v-model:loading="loading"
          :finished="finished"
          finished-text="我是有底线的"
          class="content-container"
          @load="onLoad"
      >
        <van-row gutter="10">
          <template v-if="productList.length !== 0">
            <van-col v-for="product in productList" :key="product.id" span="12" >
              <div class="good-item">
                <router-link :to="{ name: 'coinsBuy', params: { productId }, query: { deviceNo, drawId: product.id } }">
                  <div class="good-img">
                    <van-image width="100%" :src="product.combineImg" />
                  </div>
                  <div class="good-create-at">
                    <div class="good-created-label">绘制时间：</div>
                    <div>{{ formatTime(product.createTime) }}</div>
                  </div>
                </router-link>
              </div>
            </van-col>
          </template>
          <template v-if="productList.length === 0">
            <van-col :span="24">
              <van-empty description="暂无内容" />
            </van-col>
          </template>
        </van-row>
      </van-list>
    </div>

    <!-- 创作按钮 -->
    <div class="buy-btn-box">
      <template v-if="[5,17].includes(typeLogo)">
        <router-link :to="{ name: 'coinsMaterialCreateSquare', params: { productId }, query: { deviceNo, typeLogo } }">
          <van-button round type="success" class="customize-btn">进行新的创作</van-button>
        </router-link>
      </template>
      <template v-else-if="typeLogo === 19">
        <router-link :to="{ name: 'coinsMaterialCreateArcText', params: { productId }, query: { deviceNo, typeLogo } }">
          <van-button round type="success" class="customize-btn">进行新的创作</van-button>
        </router-link>
      </template>
      <template v-else>
        <router-link :to="{ name: 'coinsMaterialCreate', params: { productId }, query: { deviceNo, typeLogo } }">
          <van-button round type="success" class="customize-btn">进行新的创作</van-button>
        </router-link>
      </template>
    </div>
  </div>
</template>

<script>
import { Button, Col, Empty, List, Image as VanImage, Row, Swipe, SwipeItem, Cell } from 'vant';
import { onMounted, ref } from 'vue';
import { request } from '@/utils/request';
import { getUrlParam } from '@/utils/auth';
import { amountFormat } from '@/utils/util';
import router from "@/router";
import { useRoute } from "vue-router";

export default {
  components: {
    [Button.name]: Button,
    [Cell.name]: Cell,
    [Col.name]: Col,
    [Empty.name]: Empty,
    [List .name]: List,
    [VanImage.name]: VanImage,
    [Row.name]: Row,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
  },
  setup() {
    const productList = ref([]);
    // 接受请求参数
    const route = useRoute();
    const brands = ref([]);
    const finished = ref(false);
    const loading = ref(true);
    const contactNumber = ref('');
    const pageNo = ref(0);
    const typeLogo = ref('');
    const { params: { productId } } = route;
    // 接受请求参数
    const deviceNo = getUrlParam('deviceNo');

    onMounted(async () => {
      // 加载设备类型
      await onDeviceInfo();
      // 加载商品列表
      await onLoad();
    });

    const onDeviceInfo = async () => {
      await request({
        url: '/h5/device/getDeviceInfo',
        method: 'get',
        params: { deviceNo }
      }).then((res) => {
        if (res.success === true && res.result) {
          typeLogo.value = res.result.typeLogo;
        }
      }).catch(({ response }) => {
        const { data: { message }} = response;
        router.push({ name: 'error', query: { error: message }})
      });
    };

    const onLoad = () => {
      pageNo.value++;
      request({
        url: '/h5/pagingPcDraw',
        method: 'get',
        params: { deviceNo, pageNo: pageNo.value, typeLogo: typeLogo.value },
      }).then((res) => {
        if (res.success === true) {
          productList.value.push(...res.result.data);
          // 数据全部加载完成
          finished.value = res.result.total <= productList.value.length;
          // 加载状态结束
          loading.value = false;
        }
      }).catch(({ response }) => {
        const { data: { message }} = response;
        // showFailToast('请求出错，请稍后再试！');
        router.push({ name: 'error', query: { error: message }})
      });
    };

    const formatTime = (timestamp) => {
      // 创建一个Date对象，传入时间戳作为参数
      const date = new Date(timestamp);

      // 使用Date对象的方法获取年月日时分秒
      const year = date.getFullYear(); // 年
      const month = ('0' + (date.getMonth() + 1)).slice(-2); // 月，注意月份需要加1
      const day = ('0' + date.getDate()).slice(-2); // 日
      const hours = ('0' + date.getHours()).slice(-2); // 时
      const minutes = ('0' + date.getMinutes()).slice(-2); // 分

      // 构造格式化后的时间字符串
      return `${year}-${month}-${day} ${hours}:${minutes}`;
    }

    return {
      brands,
      productList,
      loading,
      finished,
      deviceNo,
      pageNo,
      contactNumber,
      productId,
      typeLogo,

      amountFormat,
      onLoad,
      formatTime,
    };
  },
}
</script>

<style lang="less" scoped>
.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #F7F8FA;
  .content {
    padding: 0 0.7rem;
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
  }
  .head-title {
    display: flex;
    padding: 1rem 1.5rem;
    background-color: #fff;
    margin-top: 1rem;
    margin-bottom: 1rem;
    border-radius: 0.7rem;
    justify-content: space-between;
    width: 100%;
    overflow: hidden;
    color: #000;
    font-size: 1.1rem;
    font-weight: 600;
  }
  .brand-container {
    margin-bottom: 10px;
    width: 100%;
    height: 260px;
  }
  .ad-container {
    width: 100%;
    height: 80px;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    border: 1px solid red;
  }
  .content-container {
    width: 100%;
    flex: 1;
    overflow-y: auto;
  }
  .good-item {
    margin-bottom: 1.2rem;
    background-color: #fff;
    border-radius: 1rem;
    overflow: hidden;
    padding-bottom: 0.7rem;
    .good-img {
      padding: 0.2rem;
      overflow: hidden;
      width: 100%;
      height: 12rem;
      position: relative;
      z-index: 0;
    }
    .good-create-at {
      color: #000;
      font-size: 0.9rem;
      padding: 0.7rem 1rem;
      .good-created-label {
        margin-bottom: 0.3rem;
      }
    }
  }
  .buy-btn-box {
    background-color: #fff;
    padding: 1rem 0.7rem;
    .van-button {
      width: 100%;
      font-size: 1.2rem;
    }

    .not-customize-btn {
      color: dimgray;
      border: 1px solid darkgray;
    }
    .customize-btn {
      color: #fff;
      font-weight: 600;
      background: #ffc360;
      border: none;
    }
  }
}
</style>
