<template>
  <div class="container">
    <template v-if="loading">
      <div class="init-container">
        <van-skeleton title :row="3" />
        <van-loading vertical size="24px">加载中</van-loading>
      </div>
    </template>

    <template v-else>
      <div class="content-container">
        <!-- 商品详情 -->
        <div class="good-item">
          <!-- 商品图片 -->
          <div class="good-img">
            <van-image width="100%" height="100%" fit="cover" position="cover" :src="product.imageUrl" />
          </div>
          <!-- 商品详情 -->
          <div class="good-info">
            <div class="van-multi-ellipsis--l2 good-name" v-html="product.productName"></div>
            <div v-if="product.description" class="good-description">商品介绍：{{ product.description }}</div>
            <div class="van-ellipsis price-box">
              <div>&yen; <span class="good-price">{{ amountFormat(product.price) }} </span> / {{ product.quantity }} {{ product.unit }}</div>
              <div class="good-stock">库存：{{ product.amount }}</div>
            </div>
            <van-cell title="购买数量" class="good-buy-num" v-if="![12,15].includes(typeLogo)">
              <template #value>
                <van-stepper theme="round" min="1" :max="product.amount" integer v-model="num" button-size="18" />
              </template>
            </van-cell>
          </div>
        </div>
      </div>
      <!-- 购买按钮 -->
      <div class="buy-btn-box">
        <van-button :icon="buyIcon" color="#fff" block @click="sendPay" :disabled="props.product?.amount <= 0">
          {{ props.bizSource === 2 ? '支付押金' : '立即购买' }}
        </van-button>
      </div>
      <!-- 广告 -->
      <div class="ad-container">
        <div id="goods-ad"></div>
      </div>
    </template>
  </div>
</template>

<script>
import {
  Button,
  Loading,
  Image as VanImage,
  Field,
  Stepper,
  Skeleton,
  Cell,
} from 'vant';
import { nextTick, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import { amountFormat } from '@/utils/util';
import { request } from '@/utils/request';
import { getUrlParam } from '@/utils/auth';
import buyIcon from '@/assets/buy_icon.png';
import router from "@/router";

export default {
  props: {
    typeLogo: {
      type: [String, Number],
      default: '',
    },
    contactNumber: {
      type: [String, Number],
      default: '',
    },
    bizSource: {
      type: [String, Number],
      default: '',
    },
  },
  components: {
    [Button.name]: Button,
    [Cell.name]: Cell,
    [Field.name]: Field,
    [Loading.name]: Loading,
    [VanImage.name]: VanImage,
    [Stepper.name]: Stepper,
    [Skeleton.name]: Skeleton,
  },
  setup(props) {
    // 接受请求参数
    const route = useRoute();
    const { params: { productId } } = route;
    const loading = ref(true);
    const disableReceiveBtn = ref(false);
    const num = ref(1);
    // 接受请求参数
    const deviceNo = getUrlParam('deviceNo');

    const product = ref({
      productId: productId,
      productName: '',
      price: 10,
      quantity: 0,
      imageUrl: '',
      unit: '',
      name: '',
    });

    onMounted(async () => {
      // 加载产品数据
      await onLoad();
      // 获取广告
      getTencentGDT();
    });

    const onLoad = async () => {
      loading.value = true;
      await request({
        url: `/h5/device/getProByDevNoAndProId`,
        method: 'get',
        params: { deviceNo, productId },
      }).then((res) => {
        if (res.success === true) {
          product.value = { ...product.value, ...res.result };

          // 加载状态结束
          nextTick(() => {
            loading.value = false;
          });
        }
      }).catch(({ response }) => {
        const { data: { message }} = response;
        router.push({ name: 'error', query: { error: message }})
      });
    };

    const getTencentGDT = () => {
      // 广告示例声明
      TencentGDT.push({
        app_id: '1206897647',
        placement_id: '6019843590959528', // 广告位id，请从联盟平台广告位获取
        type: "native", // 广告类型
        display_type: "banner", // 广告播放类型: banner广告
        carousel: 6000, // 轮播时间，> 6000数字类型，否则轮播会被取消
        containerid: "goods-ad", // 广告容器
        onComplete: function (res) {
          console.log("横幅广告", res);
          if (res.ret == 0) {
            console.log("广告播放成功");
          } else {
            console.log("广告播放失败");
          }
        },
      });
    }

    // 发起支付
    const sendPay = () => {
      const query = {
        name: product.value.name,
        deviceNo,
        count: num.value,
        timeStamp: Math.floor(Date.now() / 1000),
      };
      router.push({ name: 'pay', query })
    }

    return {
      props,
      num,
      product,
      loading,
      disableReceiveBtn,
      buyIcon,

      sendPay,
      amountFormat,
    }
  }
}
</script>

<style scoped lang="less">
.container {
  padding: 5px;
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #FDF7FF;
  .init-container {
    background-color: #FDF7FF;
    padding: 20px 0;
  }
  .ad-container {
    width: 100%;
    height: 80px;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  .content-container {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    border-radius: 5px;
    margin-bottom: 1.5rem;
    padding-top: 20px;
    .good-item {
      width: 90%;
      margin: 0 auto;
      font-size: 1rem;
      .good-img {
        overflow: hidden;
        width: 84%;
        height: 250px;
        position: relative;
        z-index: 0;
        margin: 0 auto;
        border-radius: 15px;
        border: 2px solid #d6d4d4;
      }
      .good-info {
        background-color: #fff;
        border: 1px solid #d6d4d4;
        border-radius: 15px;
        overflow: hidden;
        margin: 1.5rem auto 0;
        padding: 1rem 8%;
      }
      .good-name {
        color: #000;
        font-size: 1.2rem;
        font-weight: 600;
        height: 3rem;
      }
      .good-description {
        color: #000;
        height: 9rem;
        line-height: 1.5rem;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 5;
        line-break: anywhere;
        -webkit-box-orient: vertical;
      }
      .price-box {
        color: #6D6D6D;
        font-size: 0.8rem;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #6D6D6D;
        padding-bottom: 5px;
        .good-unit {
          color: darkgray;
        }
        .good-price {
          font-size: 1.2rem;
          color: #000;
        }
      }
      .good-buy-num {
        padding: 0;
        margin-top: 1rem;
        :deep(.van-stepper--round .van-stepper__plus) {
          color: #fff;
          background-color: #5755B3;
        }
        :deep(.van-stepper--round .van-stepper__minus) {
          border: none;
          background-color: #5755B3;
          color: #fff;
        }
        :deep(.van-stepper__input) {
          font-size: 1.3rem;
          font-weight: 600;
        }
      }
    }
  }
  .buy-btn-box {
    width: 90%;
    margin: 0 auto;
    border: 1px solid #858487;
    border-radius: 10px;

    .van-button {
      color: #5755B3 !important;
      font-size: 1.5rem;
      border-radius: 10px;
      font-weight: 600;
      height: 3rem;
    }
  }
}
</style>
