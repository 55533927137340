import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router';
// 定义路由
import home from '../view/home'

import manual from '../view/pay'
import goods from '../view/goods'
import goodsDetail from '../view/goodsDetail'
import coins from '../view/coins'
import coinsDetail from '../view/coinsDetail'
import coinsBuy from '../view/coinsBuy'
import coinsMaterial from '../view/coinsMaterial'
import coinsMaterialCreate from '../view/coinsMaterial/create'
import coinsMaterialCreateSquare from '../view/coinsMaterial/createSquare'
import coinsMaterialCreateArcText from '../view/coinsMaterial/createArcText'
import coinsMaterialManage from '../view/coinsMaterial/manage'
import order from '../view/order'
import my from '../view/my'
import scanReceiveProduct from '../view/scanReceiveProduct'
// import details from '../view/details'
// import user from '../view/user'
// import record from '../view/record'
// import detailed from '../view/detailed'
// import cash from '../view/cash'
// import code from '../view/code'
// import devices from '../view/devices'
// import face from '../view/face'
// import info from '../view/info'
import error from '../view/error'

import { getLocalToken } from "../utils/token";
import { showFailToast } from "vant";
import { authBase, authUserInfo, getUrlParam } from "../utils/auth";
import { deleteParams } from "@/utils/util";

const ua = window.navigator.userAgent.toLowerCase();
// const routerHistory = createWebHashHistory()
const routerHistory = createWebHistory()
// 创建路由实例
const router = createRouter({
  // 采用hash 模式
  history: routerHistory,
  // 采用history 模式
  // history: routerHistory,
  // 定义路由实例
  routes: [
    {
      name: 'home',
      path: '/home',
      component: home,
      meta: {
        title: '首页',
      }
    },
    {
      name: 'pay',
      path: '/pay',
      component: manual,
      meta: {
        title: '支付',
      },
    },
    {
      name: 'goods',
      path: '/goods',
      component: goods,
      meta: {
        title: '商品列表',
      }
    },
    {
      name: 'goodsDetail',
      path: '/goodsDetail/:productId(\\d+)',
      component: goodsDetail,
      meta: {
        title: '商品详情',
      }
    },
    {
      name: 'scanReceiveProduct',
      path: '/scanReceiveProduct/:productId(\\d+)',
      component: scanReceiveProduct,
      meta: {
        title: '扫码领取产品',
      }
    },
    {
      name: 'coins',
      path: '/coins',
      component: coins,
      meta: {
        title: '商品列表',
      }
    },
    {
      name: 'coinsDetail',
      path: '/coinsDetail/:productId(\\d+)',
      component: coinsDetail,
      meta: {
        title: '商品详情',
      }
    },
    {
      name: 'coinsBuy',
      path: '/coinsBuy/:productId(\\d+)',
      component: coinsBuy,
      meta: {
        title: '商品下单',
      }
    },
    {
      name: 'coinsMaterial',
      path: '/coinsMaterial/:productId(\\d+)',
      component: coinsMaterial,
      meta: {
        title: '素材库',
      }
    },
    {
      name: 'coinsMaterialManage',
      path: '/coinsMaterial/manage',
      component: coinsMaterialManage,
      meta: {
        title: '素材库管理',
      }
    },
    {
      name: 'coinsMaterialCreate',
      path: '/coinsCreate/:productId(\\d+)',
      component: coinsMaterialCreate,
      meta: {
        title: '商品制作',
      }
    },
    {
      name: 'coinsMaterialCreateSquare',
      path: '/coinsCreateSquare/:productId(\\d+)',
      component: coinsMaterialCreateSquare,
      meta: {
        title: '商品制作',
      }
    },
    {
      name: 'coinsMaterialCreateArcText',
      path: '/coinsCreateArcText/:productId(\\d+)',
      component: coinsMaterialCreateArcText,
      meta: {
        title: '商品制作',
      }
    },
    {
      name: 'my',
      path: '/my',
      component: my,
      meta: {
        title: '我的',
      }
    },
    {
      name: 'order',
      path: '/order',
      component: order,
      meta: {
        title: '订单列表',
      }
    },
    // {
    //   name: 'details',
    //   path: '/details/:placeAlias',
    //   component: details,
    //   meta: {
    //     title: '兑奖详情',
    //   },
    // },
    {
      name: 'error',
      path: '/error',
      component: error,
      meta: {
        title: 'error'
      }
    }
  ]
})

// 全局守卫：登录拦截 本地没有存token,请重新登录
router.beforeEach((to, from, next) => {
  // let list = ["manual", "details", "user", "record", "cash", "detailed"]
  // 判断有没有登录
  switch (to.name) {
    case "home":
    case "pay":
    case 'goods':
    case 'goodsDetail':
    case 'coins':
    case 'coinsDetail':
    case 'coinsBuy':
    case 'coinsMaterial':
    case 'coinsMaterialCreate':
    case 'coinsMaterialCreateSquare':
    case 'coinsMaterialCreateArcText':
    case 'coinsMaterialManage':
    case 'order':
    case 'scanReceiveProduct':
    case 'my':
      if (ua.indexOf('micromessenger') === -1 && ua.indexOf('alipay') === -1) {
        next({ name: 'error', query: { error: '请使用支付宝或微信扫码支付' } });
        return;
      }

      // 本地开发调试使用token 不要删除!
      // setLocalToken('45332008f4f74f5b9f5c5c00c2d2086a');

      // 当前用户环境为微信/支付宝环境且没有用户token时，需要先静默授权
      if ((ua.indexOf('micromessenger') > -1 || ua.indexOf('alipay') > -1) && !getLocalToken()) {
        authBase();
      } else {
        // 截取路径中的code，如果没有就去微信授权，如果已经获取到了就直接传code给后台获取token
        const code = getUrlParam("code")
        if (code) {
          authUserInfo();
          return;
        }
        next();
      }
      break;
    case "error":
      next();
      break;
    // case "manual":
    // case "details":
    // case "user":
    // case "record":
    // case "cash":
    // case "detailed":
    // case "info":
    // case "face":
    //   if (!getLocalToken()) {
    //     showFailToast('登录态过期, 重新登陆中...');
    //     authBase()
    //     // next("/error");
    //   } else {
    //     next();
    //   }
    //   break;
    default:
      next("/error");
  }
});
export default router
