<template>
  <good1 v-if="typeLogo != 14  && typeLogo !== ''" :contact-number="contactNumber" :type-logo="typeLogo" :biz-source="bizSource" />
  <good2 v-else-if="typeLogo == 14" :contact-number="contactNumber" :type-logo="typeLogo" :biz-source="bizSource" />
  <template v-else>
    <div class="init-container"></div>
  </template>
</template>

<script>
import { Loading } from 'vant';
import Good1 from "@/view/goodsDetail/components/Good1";
import Good2 from "@/view/goodsDetail/components/Good2";
import { getUrlParam } from "@/utils/auth";
import { onMounted, ref } from "vue";
import { request } from "@/utils/request";
import router from "@/router";

export default {
  components: {
    [Loading.name]: Loading,
    Good1,
    Good2,
  },
  setup() {
    const typeLogo = ref('');
    const bizSource = ref('');
    const contactNumber = ref('');
    // 接受请求参数
    const deviceNo = getUrlParam('deviceNo');

    onMounted(async () => {
      // 加载客服电话
      await getBizInfo();
      // 加载设备类型
      await onDeviceInfo();
    });

    const onDeviceInfo = async () => {
      await request({
        url: '/h5/device/getDeviceInfo',
        method: 'get',
        params: { deviceNo }
      }).then((res) => {
        if (res.success === true && res.result) {
          typeLogo.value = res.result.typeLogo;
        }
      }).catch(({ response }) => {
        const { data: { message }} = response;
        router.push({ name: 'error', query: { error: message }})
      });
    };

    const getBizInfo = async () => {
      await request({
        url: '/h5/getBizInfo',
        method: 'get',
        params: {deviceNo},
      }).then((res) => {
        if (res.success === true) {
          contactNumber.value = res.result?.serviceTel || '';
          bizSource.value = res.result?.source || '';
        }
      }).catch(({response}) => {
        const {data: {message}} = response;
        router.push({name: 'error', query: {error: message}})
      });
    };

    return {
      typeLogo,
      bizSource,
      contactNumber,
    };
  },
}
</script>

<style lang="less" scoped>

</style>
