<template>
  <good-list1 v-if="typeLogo != 14 && typeLogo !== ''" :contact-number="contactNumber" :type-logo="typeLogo" :biz-source="bizSource" />
  <good-list2 v-else-if="typeLogo == 14" :contact-number="contactNumber" :type-logo="typeLogo" :biz-source="bizSource" />
  <div v-else class="tem-container">
    <van-skeleton title avatar :row="3" />
    <van-skeleton title avatar :row="3" />
    <van-skeleton title avatar :row="3" />
    <van-skeleton title avatar :row="3" />
    <van-skeleton title avatar :row="3" />
    <van-skeleton title avatar :row="3" />
  </div>
</template>

<script>
import { Skeleton } from 'vant';
import { onMounted, ref } from 'vue';
import { request } from '@/utils/request';
import { getUrlParam } from '@/utils/auth';
import router from "@/router";
import GoodList1 from "@/view/goods/components/GoodList1";
import GoodList2 from "@/view/goods/components/GoodList2";

export default {
  components: {
    [Skeleton.name]: Skeleton,
    GoodList1,
    GoodList2,
  },
  setup() {
    const typeLogo = ref('');
    const bizSource = ref('');
    const contactNumber = ref('');
    // 接受请求参数
    const deviceNo = getUrlParam('deviceNo');

    onMounted(async () => {
      // 加载客服电话
      await getBizInfo();
      // 加载设备类型
      await onDeviceInfo();
    });

    const onDeviceInfo = async () => {
      await request({
        url: '/h5/device/getDeviceInfo',
        method: 'get',
        params: { deviceNo }
      }).then((res) => {
        if (res.success === true && res.result) {
          typeLogo.value = res.result.typeLogo;
        }
      }).catch(({ response }) => {
        const { data: { message }} = response;
        router.push({ name: 'error', query: { error: message }})
      });
    };

    const getBizInfo = async () => {
      await request({
        url: '/h5/getBizInfo',
        method: 'get',
        params: {deviceNo},
      }).then((res) => {
        if (res.success === true) {
          contactNumber.value = res.result?.serviceTel || '';
          bizSource.value = res.result?.source || '';
        }
      }).catch(({response}) => {
        const {data: {message}} = response;
        router.push({name: 'error', query: {error: message}})
      });
    };

    return {
      typeLogo,
      bizSource,
      contactNumber,
    };
  },
}
</script>

<style lang="less" scoped>
.tem-container {
  width: 100%;
  height: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #fff;
}
</style>
